export enum Steps {
  Demographics = 1,
  Id,
  Consent,
  Calendar,
  Availability,
  Confirm,
}

export enum CTLSteps {
  Demographics = 1,
  Consent,
  CTLDemographics,
  CTLLifestyle,
  Confirm = 6,
}
export interface Appointment {
  patientId: number
  startTime: string // i.e. '2020-10-01T08:00:00'
  endTime: string // i.e. '2020-10-01T08:30:00'
  localStartTime: string // i.e. '8:00 AM CDT'
  localEndTime: string // i.e. '8:30 AM CDT'
  pharmacistId: number
  pharmacist: string
  listItemValueContactId: number
  appointmentDescription: string
  appointmentId: number
}

export interface AppointmentTime {
  pharmacistGuid: string
  startTime: string
  endTime: string
}
export interface AppointmentDate {
  date: string
  appointmentTimes: AppointmentTime[]
}
export interface AppointmentProgress {
  currentStep: number
  idStep: boolean
  infoStep: boolean
  policyStep: boolean
  conditionStep: boolean
  appointmentStep: boolean
}

export interface AppointmentResponse {
  immediateAppointment: boolean
  canSchedule: boolean
  canReschedule: boolean
  scheduleId: number
  title: string
  message: string
  helpText: string
  scheduledAppointment: string
  isInitial: boolean
  appointmentDates: AppointmentDate[]
}

export interface AppointmentRequest {
  enrollmentFormGuid?: string
  immediateAppointment: boolean
  scheduleId?: number
  startTime: string
  endTime: string
  pharmacist: string
}

export interface DemographicFormValues {
  firstName: string | undefined
  lastName: string | undefined
  email: string | undefined
  emailAddress2: string | undefined
  streetAddress: string | undefined
  phone: string | undefined
  zipCode: string | undefined
  dateOfBirth: string | undefined
  langPref: string | undefined
  deviceType: number | undefined
  isChooseToLose: boolean
}

export interface InfoFormValues {
  carrierPId: number | undefined
  enrollmentFormGuid?: string
  heightFt: string | undefined
  heightIn: string | undefined
  weight: string | undefined
  gender: string | undefined
  goalWeight: string | undefined
}

export interface CTLLifestyleFormValues {
  enrollmentFormGuid?: string
  reasonForChange: number | undefined
  readinessToChange: number | undefined
  dietVariety: number | undefined
  mealsSameTime: number | undefined
  cookingSkills: number | undefined
  dietStrategy: string | undefined
  livingSituation: number | undefined
  householdMembersTryingToLoseWeight: number | undefined
  timeForYourself: number | undefined
  obligations: number | undefined
  activityLevel: string | undefined
  reasonForChangeNote: string | undefined
  livingSituationNote: string | undefined
  obligationsNote: string | undefined
}

export interface PolicyFormValues {
  enrollmentFormGuid?: string
  fullName: string
  viewedPrivacyPolicy: boolean
  viewedCommunicationPolicy: boolean
  viewedTermsAndConditions: boolean
  textOptIn: boolean
}

export interface TobaccoFormValues {
  enrollmentFormGuid?: string
  isSTOP: boolean
}
