import Checkbox from 'Components/Base/Checkbox'
import Column from 'Components/Base/Column'
import Icon from 'Components/Base/Icon'
import ResponsiveContainer from 'Components/Base/ResponsiveContainer'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import { theme } from 'Styles/styledTheme'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CTLDetails } from 'types/CTL'
import { MobileOS } from 'types/MobileOS'
import getOS from 'utilities/getMobileOS'
import FlipCard from './FlipCard'

type Props = {
  programDetails: CTLDetails | undefined
  clientCode: string
  applyButtonDisabled: boolean
  onSetApplyButtonDisabled: (value: boolean) => void
  isMobile
}
export default function CTLProgramInfo({ programDetails, clientCode, applyButtonDisabled, onSetApplyButtonDisabled, isMobile }: Props) {
  const [openCard, setOpenCard] = useState<number | undefined>()
  const navigate = useNavigate()

  const navigateToEnrollment = () => {
    navigate(`/appointment?ctl=true&cid=${programDetails?.carrierPId}`)
  }

  const changeOpenCard = (card: number) => {
    if (card === openCard) {
      setOpenCard(undefined)
    } else {
      setOpenCard(card)
    }
  }

  return (
    <>
      <ResponsiveContainer>
        <Column className="ctl-left-column">
          <Typography size={32} font="bold" styles={{ marginBottom: 20 }} color={theme.colors.primary1}>
            {programDetails && programDetails.friendlyName !== '' ? programDetails.friendlyName : 'Choose to Lose'}
          </Typography>
          <Typography size={64} font="bold" styles={{ marginBottom: 20, lineHeight: '75px' }}>
            Your Path to Health and Happiness Starts Here
          </Typography>
          <Typography size={24} styles={{ marginBottom: 30 }}>
            Choose to Lose is a personalized weight management program that connects you with a dedicated health coach. Using the Tria Health app,
            you’ll easily track your meals and collaborate with your coach to create a customized plan, setting you up for lasting success.
          </Typography>
          {programDetails && programDetails.customProgramOptIn !== '' && (
            <Row styles={{ marginTop: 20 }}>
              <Checkbox checked={!applyButtonDisabled} styles={{ marginRight: 5 }} onClick={() => onSetApplyButtonDisabled(!applyButtonDisabled)} />
              <Typography size={18} styles={{ marginBottom: 10 }}>
                {programDetails.customProgramOptIn}
              </Typography>
            </Row>
          )}
          {programDetails && !programDetails.programClosed ? (
            applyButtonDisabled ? (
              <></>
            ) : (
              <Row>
                <button onClick={navigateToEnrollment} className="apply-now">
                  <Typography color={'#fff'} font="bold" size={18} styles={{ marginRight: 'auto', marginLeft: 'auto', marginTop: 4 }}>
                    Apply Now
                  </Typography>
                </button>
                <a href="https://vimeo.com/1033149712" target="_blank" rel="noreferrer" className="how-it-works">
                  <Icon name="play-start" color={theme.colors.primary1} styles={{ marginLeft: 'auto', marginRight: 10 }} size="2xl" />
                  <Typography color={theme.colors.primary1} font="bold" size={18} styles={{ marginRight: 'auto', marginTop: 4 }}>
                    How it Works
                  </Typography>
                </a>
              </Row>
            )
          ) : (
            <div style={{ height: 25 }} />
          )}
          {programDetails && programDetails.message && (
            <Row styles={{ marginBottom: 10 }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: programDetails.message,
                }}
              />
            </Row>
          )}
          {programDetails && programDetails.customProgramInfo && (
            <Row styles={{ marginBottom: 10 }}>
              <div
                style={{ fontWeight: 'bold' }}
                dangerouslySetInnerHTML={{
                  __html: programDetails.customProgramInfo,
                }}
              />
            </Row>
          )}
        </Column>
        <img
          src={'https://triacentral.blob.core.windows.net/dev-storage/portal/images/Health%20Coach%201.png'}
          alt="health coach"
          className="health-coach-image"
        />
      </ResponsiveContainer>
      <ResponsiveContainer>
        <img
          className="logo"
          src={programDetails?.logoUrl !== '' ? programDetails?.logoUrl : `${process.env.REACT_APP_AZURE_BASE_URL}/clients/${clientCode}/logo`}
          alt={`${clientCode}-logo`}
        />
        <div className="program-req-card">
          <Row>
            <Typography size={32} styles={{ marginBottom: 10 }} font="semi-bold">
              Program Requirements:
            </Typography>
          </Row>
          {/* <Typography size={18}>
            {!programDetails?.participantType
              ? `Choose to lose is recommended for individuals who:`
              : `Choose to lose is recommended for ${programDetails.participantType} who:`}
          </Typography> */}
          {programDetails && programDetails.customCriteria.length > 0 ? (
            programDetails.customCriteria.map((criteria) => (
              <Row styles={{ marginLeft: 30 }}>
                <Typography size={24} styles={{ marginRight: 10 }}>
                  {'\u2022'}
                </Typography>
                <Typography size={24}>{criteria}</Typography>
              </Row>
            ))
          ) : (
            <>
              <Row>
                <Typography size={24}>{'\u2022'} Have a smartphone</Typography>
              </Row>
              <Row>
                <Typography size={24}>{'\u2022'} BMI of 30+ OR</Typography>
              </Row>
              <Row>
                <Typography size={24}>{'\u2022'} BMI between 27-29.9 with a chronic condition</Typography>
              </Row>
            </>
          )}
        </div>
      </ResponsiveContainer>

      {isMobile ? (
        <>
          {programDetails && programDetails.story.length > 0 && (
            <div style={{ boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.5)', borderRadius: 5, padding: 35, margin: '65px 20px', textAlign: 'center' }}>
              <Column styles={{ width: '100%' }}>
                {programDetails && programDetails.title && <Typography variant="h1">{programDetails.title}</Typography>}
                <br></br>
                {programDetails &&
                  programDetails.story &&
                  programDetails.story.map((paragraph) => {
                    return (
                      <>
                        <Typography>{paragraph.paragraph}</Typography>
                        <br></br>
                      </>
                    )
                  })}
              </Column>
              <Column styles={{ width: '100%', marginLeft: 'auto', marginTop: 'auto', marginBottom: 'auto' }}>
                <img src={programDetails?.imageUrl} alt={programDetails?.caption} />
                {programDetails && <Typography>{programDetails.caption}</Typography>}
              </Column>
            </div>
          )}
        </>
      ) : (
        <>
          {programDetails && programDetails.story.length > 0 && (
            <div style={{ boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.5)', borderRadius: 5, padding: 35, margin: '65px 20px' }}>
              <Row>
                <Column styles={{ width: '55%' }}>
                  {programDetails && programDetails.title && (
                    <Typography variant="h1" styles={{ width: '85%' }}>
                      {programDetails.title}
                    </Typography>
                  )}
                  <br></br>
                  {programDetails &&
                    programDetails.story &&
                    programDetails.story.map((paragraph) => {
                      return (
                        <>
                          <Typography>{paragraph.paragraph}</Typography>
                          <br></br>
                        </>
                      )
                    })}
                </Column>
                <Column styles={{ width: '30%', marginLeft: 'auto', marginTop: 'auto', marginBottom: 'auto' }}>
                  <img src={programDetails?.imageUrl} alt={programDetails?.caption} />
                  {programDetails && <Typography>{programDetails.caption}</Typography>}
                </Column>
              </Row>
            </div>
          )}
        </>
      )}

      <ResponsiveContainer styles={isMobile ? {} : { minHeight: 800 }}>
        <Column className="ctl-right-column">
          <>
            <img
              className="app-and-fruit"
              src="https://triacentral.blob.core.windows.net/dev-storage/portal/images/Nutrition%20Dashboard_App%201.png"
              alt={`${clientCode}-logo`}
            />
          </>
        </Column>
        <div className="why">
          <Typography size={48} styles={{ marginBottom: 20, fontWeight: 'bold', lineHeight: '58.35px' }}>
            Why Should You <span style={{ fontStyle: 'italic' }}>Choose to Lose?</span>
          </Typography>
          <Typography size={24} styles={{ marginBottom: 30 }}>
            Experience the best of both worlds: Guidance from a certified health coach, plus expertise from a pharmacist if medication is part of your
            weight loss journey. With their guidance, you'll build lasting habits for a healthier future.
          </Typography>
          <div className="accordion-card" onClick={() => changeOpenCard(1)}>
            <Row>
              <Icon
                name="user-headset"
                color="#fff"
                styles={{ backgroundColor: '#00677F', padding: 5, borderRadius: 5, marginRight: 20, marginLeft: 10 }}
              />
              <Typography size={24} font="bold" className="accordion-title">
                Expert Coaches
              </Typography>
              <Icon name="plus" color="#000" styles={{ padding: 5, borderRadius: 5 }} />
            </Row>
            {openCard === 1 && (
              <Typography size={18} styles={{ padding: '5px 50px 5px 61px' }}>
                Our health coaches are certified by the National Board of Health and Wellness Coaches and have over 5 years of experience in nutrition
                and exercise coaching.
              </Typography>
            )}
          </div>
          <div className="accordion-card" onClick={() => changeOpenCard(2)}>
            <Row>
              <Icon name="salad" color="#fff" styles={{ backgroundColor: '#00677F', padding: 5, borderRadius: 5, marginRight: 20, marginLeft: 10 }} />
              <Typography size={24} font="bold" className="accordion-title">
                No Specific Diets
              </Typography>
              <Icon name="plus" color="#000" styles={{ padding: 5, borderRadius: 5 }} />
            </Row>
            {openCard === 2 && (
              <Typography size={18} styles={{ padding: '5px 50px 5px 63px' }}>
                Our program avoids one-size-fits-all diets. Instead, we focus on helping patients make healthy choices based on their own food
                preferences, restrictions, and allergies.
              </Typography>
            )}
          </div>
          <div className="accordion-card" onClick={() => changeOpenCard(3)}>
            <Row>
              <Icon name="badge" color="#fff" styles={{ backgroundColor: '#00677F', padding: 5, borderRadius: 5, marginRight: 20, marginLeft: 10 }} />
              <Typography size={24} font="bold" className="accordion-title">
                Realistic & Achievable
              </Typography>
              <Icon name="plus" color="#000" styles={{ padding: 5, borderRadius: 5 }} />
            </Row>
            {openCard === 3 && (
              <Typography size={18} styles={{ padding: '5px 50px 5px 63px' }}>
                Your health coach will help you set specific, realistic goals to ensure long-term success. This approach builds on each achievement,
                making it more than a short-term fix—it’s a new way to think about your health and relationship with food.
              </Typography>
            )}
          </div>
          <div className="accordion-card" onClick={() => changeOpenCard(4)}>
            <Row>
              <Icon
                name="file-rx"
                color="#fff"
                styles={{ backgroundColor: '#00677F', padding: '5px 8px', borderRadius: 5, marginRight: 20, marginLeft: 10 }}
              />
              <Typography size={24} font="bold" className="accordion-title">
                Clinical Oversight
              </Typography>
              <Icon name="plus" color="#000" styles={{ padding: 5, borderRadius: 5 }} />
            </Row>
            {openCard === 4 && (
              <Typography size={18} styles={{ padding: '5px 50px 5px 63px' }}>
                Interested in weight loss medications? Tria Health’s expert pharmacists are here to support you. Weight loss medications can come with
                serious side effects, so our pharmacists will carefully assess your options to ensure they’re safe, effective, and working as
                intended.
              </Typography>
            )}
          </div>
        </div>
      </ResponsiveContainer>
      <Typography size={48} font="bold" styles={{ marginLeft: 15, marginTop: 50 }}>
        Ready to Get Started?
      </Typography>
      <ResponsiveContainer>
        <FlipCard
          isMobile={isMobile}
          side1={
            <Column styles={{ margin: 0, position: 'relative', top: 46 }}>
              <Typography font="bold" size={24} color={theme.colors.primary1} styles={{ marginBottom: 35 }}>
                1. Apply Online
              </Typography>
              <Typography size={18} color="#000" styles={{ lineHeight: '21.13px' }}>
                When applications open for your group, an “Apply” button will appear at the top of this page. Click it to go to the application form.
              </Typography>
            </Column>
          }
          side2={
            <div style={{ margin: 0, position: 'relative', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
              <img
                src={'https://triacentral.blob.core.windows.net/dev-storage/portal/images/CTL_Apply%201.png'}
                alt="medication"
                style={{ margin: '12px 0px' }}
              />
              <Typography font="bold" size={24} color={theme.colors.primary1}>
                1. Apply Online
              </Typography>
            </div>
          }
        ></FlipCard>
        <FlipCard
          isMobile={isMobile}
          side1={
            <Column styles={{ margin: 0, position: 'relative', top: 46 }}>
              <Typography font="bold" size={24} color={theme.colors.primary1} styles={{ marginBottom: 35 }}>
                2. Check Email
              </Typography>
              <Typography size={18} color="#000" styles={{ lineHeight: '21.13px' }}>
                After we process your application, we’ll send you an email with next steps! Be on the lookout for an email from
                <b> ctl@triahealth.com</b>
              </Typography>
            </Column>
          }
          side2={
            <div style={{ margin: 0, position: 'relative', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
              <img
                src={'https://triacentral.blob.core.windows.net/dev-storage/portal/images/CTL_Accepted%201.png'}
                alt="medication"
                style={{ margin: '12px 0px' }}
              />
              <Typography font="bold" size={24} color={theme.colors.primary1}>
                2. Check Email
              </Typography>
            </div>
          }
        ></FlipCard>
        <FlipCard
          isMobile={isMobile}
          side1={
            <Column styles={{ margin: 0, position: 'relative', top: 46 }}>
              <Typography font="bold" size={24} color={theme.colors.primary1} styles={{ marginBottom: 35 }}>
                3. Call to Schedule
              </Typography>
              <Typography size={18} color="#000" styles={{ lineHeight: '21.13px' }}>
                Once you receive your acceptance email, give us a call to schedule your first consultation! Don’t worry - if we don’t hear from you,
                we’ll give you a call.
              </Typography>
              <Typography size={18} color="#000" styles={{ marginTop: 10, lineHeight: '21.13px' }}>
                Make sure to save our phone number:
              </Typography>
              <Typography size={18} color="#000" font="bold" styles={{ lineHeight: '21.13px' }}>
                913-322-8456
              </Typography>
            </Column>
          }
          side2={
            <div style={{ margin: 0, position: 'relative', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
              <div style={{ height: '286px', margin: '10px 0px 0px' }}>
                <img src={'https://triacentral.blob.core.windows.net/dev-storage/portal/images/CTL_Schedule%201.png'} alt="medication" />
              </div>
              <Typography font="bold" size={24} color={theme.colors.primary1}>
                3. Call to Schedule
              </Typography>
            </div>
          }
        ></FlipCard>
        <FlipCard
          isMobile={isMobile}
          side1={
            <Column styles={{ margin: 0, position: 'relative', top: 46 }}>
              <Typography font="bold" size={24} color={theme.colors.primary1} styles={{ marginBottom: 35 }}>
                4. Start Tracking
              </Typography>
              <Typography size={18} color="#000" styles={{ lineHeight: '21.13px' }}>
                Before your first consultation, start tracking your meals in the Tria Health app!
              </Typography>
              <Typography size={18} color="#000" styles={{ marginTop: 10 }}>
                <a
                  href={
                    getOS() === MobileOS.iOS
                      ? 'https://apps.apple.com/us/app/tria-health/id1541412621'
                      : 'https://play.google.com/store/apps/details?id=com.tria.health&pli=1'
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <u>Click Here</u>
                </a>{' '}
                to download
              </Typography>
            </Column>
          }
          side2={
            <div style={{ margin: 0, position: 'relative', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
              <div style={{ height: '286px', margin: '10px 0px 0px' }}>
                <img src={'https://triacentral.blob.core.windows.net/dev-storage/portal/images/CTL_Track%201.png'} alt="medication" />
              </div>
              <Typography font="bold" size={24} color={theme.colors.primary1}>
                4. Start Tracking
              </Typography>
            </div>
          }
        ></FlipCard>
      </ResponsiveContainer>
    </>
  )
}
