import Button from 'Components/Base/Button'
import Column from 'Components/Base/Column'
import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import { StyledErrorText } from 'Styles/global'
import { Formik, FormikErrors, getIn } from 'formik'
import { useAppSelector } from 'hooks/hooks'
import usePatientIntake from 'hooks/usePatientIntake'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { BeatLoader } from 'react-spinners'
import { getAccessToken } from 'reducers/authSlice'
import { useTheme } from 'styled-components'
import { IntakeMedication, IntakeMedicationFrequency } from 'types/Medication'
import * as Yup from 'yup'
import './MedicationCard.scss'

interface Props {
  cancelInput: () => void
  onAddMedication: (medication: IntakeMedication) => void
  medication: IntakeMedication
  medicationsExist: boolean
}

export default function MedicationCard({ cancelInput, onAddMedication, medication, medicationsExist }: Props) {
  const theme = useTheme()
  const token = useAppSelector(getAccessToken)
  const { getMedicationAutoCompleteInfo, getMedicationAutoCompleteStrengthInfo } = usePatientIntake({
    token: token!,
  })

  const [frequency, setFrequency] = useState<IntakeMedicationFrequency | undefined>(undefined)
  const [attemptedSubmit, setAttemptedSubmit] = useState(false)
  const [formHide, setFormHide] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [strengthValue, setStrengthValue] = useState('')
  const [nameOptions, setNameOptions] = useState<{ tradeName: string }[]>()
  const [strengthOptions, setStrengthOptions] = useState<{ strengthDescription: string }[]>()
  const [searched, setSearched] = useState<boolean>(false)

  function getStyles(fieldName: string, errors: FormikErrors<IntakeMedication>) {
    if (getIn(errors, fieldName)) {
      return {
        border: '2px solid red',
        boxShadow: 'none',
      }
    }
  }

  useEffect(() => {
    setStrengthValue('')
    if (searchValue.length >= 4 && nameOptions === undefined) {
      const getFoods = setTimeout(() => {
        getMedicationAutoCompleteInfo(searchValue).then((res) => {
          setNameOptions(res.data.prescriptionMedications)
          setSearched(true)
        })
      }, 2000)
      return () => clearTimeout(getFoods)
    } else if (searchValue.length < 4) {
      setNameOptions(undefined)
      setStrengthOptions(undefined)
      setSearched(false)
    }
  }, [searchValue])

  const initialValues: IntakeMedication = {
    gpi: medication.gpi,
    medicationId: medication.medicationId,
    frequency: 7,
  }
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    reason: Yup.string(),
  })

  const onSubmit = (values: IntakeMedication) => {
    values.name = searchValue
    values.strength = strengthValue
    onAddMedication(values)
    setFormHide(true)
  }

  function onCancel() {
    cancelInput()
    setFormHide(true)
  }

  function handleTradeNameClick(tradeName: string) {
    setNameOptions([])
    setSearched(true)
    setSearchValue(tradeName)
    getMedicationAutoCompleteStrengthInfo(tradeName).then((res) =>
      setStrengthOptions(res.data.strengthDescriptions.filter((s) => s.strengthDescription.length > 0)),
    )
  }

  function handleStrengthClick(strength: string) {
    setStrengthValue(strength)
    setStrengthOptions(undefined)
  }

  return (
    <Formik
      initialValues={
        medicationsExist
          ? {
              name: medication.name,
              strength: medication.strength,
              quantity: medication.quantity,
            }
          : initialValues
      }
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ handleSubmit, values, setFieldValue, isValid, errors }) => {
        return (
          <div className="intake-medication-card" style={formHide ? { display: 'none' } : {}}>
            <div onClick={onCancel} className="intake-cancel-form">
              <Icon name="times" color="white" styles={{ height: '100%', width: '40%' }} />
            </div>
            <Row styles={{ marginBottom: '20px' }}>
              <Typography font="semi-bold" color={theme.colors.primary1}>
                Other Medication:
              </Typography>
            </Row>
            <div className="intake-med-field-row">
              <Column>
                <Form.Control
                  type="text"
                  onChange={(e) => {
                    setSearchValue(e.target.value)
                    setFieldValue('name', e.currentTarget.value)
                  }}
                  style={{
                    border: '2px solid transparent',
                    borderRadius: 4,
                    ...getStyles('name', errors),
                  }}
                  value={searchValue}
                  placeholder="Medication Name *"
                  className="login-input physician-input intake-med-input"
                />
                {searchValue && searchValue.length >= 4 && !(searched && nameOptions?.length === 0) && (
                  <div className="tradeName-dropdown">
                    {nameOptions && nameOptions.length > 0 ? (
                      nameOptions
                        ?.filter((n) => n.tradeName.toLowerCase().includes(searchValue))
                        .map((n) => {
                          return (
                            <div className="tradeName-dropdown-option" onClick={() => handleTradeNameClick(n.tradeName)}>
                              <Typography className="tradeName-dropdown-option-text">{n.tradeName}</Typography>
                            </div>
                          )
                        })
                    ) : (
                      <BeatLoader size={10} color={theme.colors.primary1} />
                    )}
                  </div>
                )}
              </Column>
              <div className="med-input-spacer" />
              <Column>
                <Form.Control
                  type="text"
                  required
                  style={{
                    border: '2px solid transparent',
                    borderRadius: 4,
                    ...getStyles('strength', errors),
                  }}
                  onChange={(e) => {
                    setStrengthValue(e.currentTarget.value)
                    setFieldValue('strength', e.currentTarget.value)
                  }}
                  value={strengthValue}
                  placeholder="Strength"
                  className="login-input physician-input intake-med-input"
                />
                {strengthOptions && strengthOptions.length > 0 && (
                  <div className="tradeName-dropdown">
                    {strengthOptions
                      ?.filter((s) => s.strengthDescription.includes(strengthValue))
                      .map((n) => {
                        return (
                          <div className="tradeName-dropdown-option" onClick={() => handleStrengthClick(n.strengthDescription)}>
                            <Typography className="tradeName-dropdown-option-text">{n.strengthDescription}</Typography>
                          </div>
                        )
                      })}
                  </div>
                )}
              </Column>
              <div className="med-input-spacer" />
            </div>

            <Row styles={{ justifyContent: 'space-between' }}>
              <Form.Control
                type="text"
                onChange={(e) => setFieldValue('otherFrequency', e.currentTarget.value)}
                style={{
                  width: '100%',
                  height: 80,
                  marginRight: 5,
                  marginTop: 20,
                  border: '2px solid transparent',
                  borderRadius: 4,
                }}
                value={values.otherFrequency}
                placeholder="Prescription Directions- i.e. One tablet daily"
                className="login-input physician-input"
              />
            </Row>
            <Row styles={{ justifyContent: 'space-between' }}>
              <Form.Control
                type="text"
                onChange={(e) => setFieldValue('reason', e.currentTarget.value)}
                style={{
                  width: '100%',
                  height: 80,
                  marginRight: 5,
                  marginTop: 20,
                  border: '2px solid transparent',
                  borderRadius: 4,
                  ...getStyles('reason', errors),
                }}
                value={values.reason}
                placeholder="Reason for Taking"
                className="login-input physician-input"
              />
            </Row>
            {attemptedSubmit && isValid === false && (
              <Row styles={{ justifyContent: 'space-between' }}>
                <StyledErrorText>Please fix errors above</StyledErrorText>
              </Row>
            )}
            <Row center styles={{ marginTop: 20 }}>
              <Button pageName={`intake_Medication`} text="Cancel" className="back-button" onClick={onCancel} />
              <Button
                pageName={`intake_Medication`}
                text="Save"
                className="next-button"
                onClick={() => {
                  handleSubmit()
                  setAttemptedSubmit(true)
                }}
              />
            </Row>
          </div>
        )
      }}
    </Formik>
  )
}
