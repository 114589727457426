import { MobileOS } from '../types/MobileOS'

function iOS() {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}

const getOS = () => {
  const isiOS = iOS()
  if (isiOS === true) {
    return MobileOS.iOS
  } else {
    return MobileOS.Android
  }
  /*  if (userAgent.match('/android/i') || userAgent.indexOf('Chrome')) {
    return MobileOS.Android
  }
  if (userAgent.match('/iPad|iPhone|iPod/safari') && !window.MSStream) {
    return MobileOS.iOS
  } */
}

export default getOS
