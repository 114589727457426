import BackRow from 'Components/Base/BackRow'
import PageTitle from 'Components/Base/PageTitle/PageTitle'
import Typography from 'Components/Base/Typography'
import { sizes } from 'Styles/sizes'
import useDocumentTitle from 'hooks/useDocumentTitle'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ctlLinks, exerciseLinks, motivationLinks, otherLinks, recipeLinks } from './CTLLinks'
import ResourceSection from './components/ResourceSection'

export default function WeightLossResourcesPage() {
  const { setDocumentTitle } = useDocumentTitle()
  const [isMobile, setIsMobile] = useState<boolean>()
  setDocumentTitle('Weight Loss')

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth < 1200) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    })

    if (window.innerWidth < 1200) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [])

  const RECIPES_IMAGE = 'https://triacentral.blob.core.windows.net/dev-storage/resources/weightloss/anna-pelzer-472429-unsplash.jpg'
  const EXERCISE_IMAGE = 'https://triacentral.blob.core.windows.net/dev-storage/resources/weightloss/Tikkho%20Maciel_Unsplash.jpg'
  const MOTIVATION_IMAGE = 'https://triacentral.blob.core.windows.net/dev-storage/resources/weightloss/bruno-nascimento-149663-unsplash.jpg'
  const CTL_IMAGE = 'https://triacentral.blob.core.windows.net/dev-storage/portal/images/Withings%20Scale.jpg'
  const OTHER_IMAGE = 'https://triacentral.blob.core.windows.net/dev-storage/resources/weightloss/siora-photography-632987-unsplash.jpg'

  return (
    <StyledPageContainer>
      <BackRow />
      <div className={'text-container'}>
        <div style={{ marginBottom: 40 }}>
          <PageTitle title={'Weight Loss Resources'} styles={{ marginBottom: 40 }} bold />
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <ResourceSection title="Recipes" image={RECIPES_IMAGE} links={recipeLinks} />
            <ResourceSection title="Exercise" image={EXERCISE_IMAGE} links={exerciseLinks} />
            <ResourceSection title="Motivation" image={MOTIVATION_IMAGE} links={motivationLinks} />
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: isMobile ? 0 : 80, marginBottom: 80 }}>
            <ResourceSection title="Choose To Lose" image={CTL_IMAGE} links={ctlLinks} />
            <ResourceSection title="Other" image={OTHER_IMAGE} links={otherLinks} />
          </div>
        </div>
      </div>
    </StyledPageContainer>
  )
}

const StyledTitle = styled(Typography)`
  margin-bottom: 20px;
  font-size: 26px;
  @media (min-width: ${sizes.tablet}) {
    font-size: 40px;
  }
`

const StyledPageContainer = styled.div`
  margin: 80px 6% 30% 6%;
  padding-left: 0px;
  @media (min-width: ${sizes.tablet}) {
    padding-left: 100px;
    padding-top: 50px;
    margin-bottom: 100px;
    margin-top: 0px;
  }
`
