import { AxiosResponse } from 'axios'
import qs from 'querystring'
import { CTLLifestyleFormValues, DemographicFormValues, InfoFormValues, PolicyFormValues } from 'types/Appointment'
import { AvailabilityFormValues } from '../Pages/AppointmentPage/components/AvailabilityForm'
import { useApi } from './useAPI'

type WelltrakResponse = {
  wellTrakFound: boolean
  wellTrakId: number
  hasStop: boolean
  isNoPA: boolean
}
export default function useEnrollment() {
  const TriaApi = useApi()

  const postDemographics = async (values: DemographicFormValues) => {
    const { firstName, lastName, email, phone, zipCode, dateOfBirth, langPref, deviceType, isChooseToLose } = values

    const data = qs.stringify({
      FirstName: firstName,
      LastName: lastName,
      Email: email,
      Phone: phone,
      ZipCode: zipCode,
      DateOfBirth: dateOfBirth,
      LangPref: langPref,
      DeviceType: deviceType,
      IsChooseToLose: isChooseToLose,
    })

    const response = TriaApi.post(`api/enrollment/demographics`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })

    return response
  }

  const postCTLDemographics = async (values: InfoFormValues) => {
    const { carrierPId, enrollmentFormGuid, gender, heightFt, heightIn, weight, goalWeight } = values

    const data = qs.stringify({
      CarrierPId: carrierPId,
      EnrollmentFormGuid: enrollmentFormGuid,
      Gender: gender,
      HeightFeet: heightFt,
      heightInches: heightIn,
      Weight: weight,
      GoalWeight: goalWeight,
    })

    const response = TriaApi.post(`api/enrollment/demographics-ctl`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })

    return response
  }

  const postCTLLifestyle = async (values: CTLLifestyleFormValues) => {
    const {
      enrollmentFormGuid,
      reasonForChange,
      readinessToChange,
      dietVariety,
      mealsSameTime,
      cookingSkills,
      dietStrategy,
      livingSituation,
      householdMembersTryingToLoseWeight,
      timeForYourself,
      obligations,
      activityLevel,
      livingSituationNote,
      obligationsNote,
      reasonForChangeNote,
    } = values

    const data = qs.stringify({
      EnrollmentFormGuid: enrollmentFormGuid,
      ReasonForChange: reasonForChange,
      ReadinessToChange: readinessToChange,
      DietVariety: dietVariety,
      MealsSameTime: mealsSameTime,
      CookingSkills: cookingSkills,
      DietStrategy: dietStrategy,
      LivingSituation: livingSituation,
      HouseholdMembersTryingToLoseWeight: householdMembersTryingToLoseWeight,
      TimeForYourself: timeForYourself,
      Obligations: obligations,
      ActivityLevel: activityLevel,
      LivingSituationNote: livingSituationNote,
      ObligationsNote: obligationsNote,
      ReasonForChangeNote: reasonForChangeNote,
    })

    const response = TriaApi.post(`api/enrollment/lifestyle-ctl`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })

    return response
  }

  const postWelltrak = async (values: WelltrakRequest): Promise<AxiosResponse<WelltrakResponse>> => {
    const { enrollmentFormGuid, welltrakID } = values
    const data = {
      EnrollmentFormGuid: enrollmentFormGuid,
      WelltrakID: welltrakID,
    }
    const response = TriaApi.post(`api/enrollment/welltrak`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    return response
  }

  const postPolicy = async (values: PolicyFormValues) => {
    const { enrollmentFormGuid, viewedCommunicationPolicy, viewedPrivacyPolicy, viewedTermsAndConditions, textOptIn, fullName } = values

    const data = {
      EnrollmentFormGuid: enrollmentFormGuid,
      PrivacyPolicy: viewedPrivacyPolicy,
      CommunicationPolicy: viewedCommunicationPolicy,
      TermsAndConditions: viewedTermsAndConditions,
      TextAlerts: textOptIn,
      ESignature: fullName,
    }

    const response = TriaApi.post(`api/enrollment/privacypolicy`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    return response
  }

  const postEnrollmentAvailability = async (values: AvailabilityFormValues) => {
    const { enrollmentFormGuid, days, time } = values

    const data = {
      EnrollmentFormGuid: enrollmentFormGuid,
      Days: days,
      TimeOfDay: time,
    }

    const response = TriaApi.post(`api/enrollment/availability`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    return response
  }

  const postFollowUpAvailability = async (values: AvailabilityFormValues) => {
    const { enrollmentFormGuid, days, time } = values

    const data = qs.stringify({
      EnrollmentFormGuid: enrollmentFormGuid,
      Days: days,
      TimeOfDay: time,
    })

    const response = TriaApi.post(`api/appointment/availability`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })

    return response
  }

  return {
    postWelltrak,
    postDemographics,
    postPolicy,
    postEnrollmentAvailability,
    postFollowUpAvailability,
    postCTLDemographics,
    postCTLLifestyle,
  }
}

export interface WelltrakRequest {
  enrollmentFormGuid?: string
  welltrakID?: string
}

export interface DemographicsRequest {
  firstName: string
  lastName: string
  email: string
  phone: string
  zipCode: string
  dateOfBirth: string
}
