export const recipeLinks = [
  {
    title: 'American Heart Association',
    url: 'https://recipes.heart.org',
  },
  {
    title: 'Academy of Nutrition and Dietetics',
    url: 'http://www.eatright.org/food',
  },
  {
    title: 'Grocery List Template',
    url: 'https://triacentral.blob.core.windows.net/dev-storage/resources/weightloss/CTL_GroceryList.pdf',
  },
  {
    title: 'Basic Nutrition Guidelines',
    url: 'https://triacentral.blob.core.windows.net/dev-storage/resources/weightloss/CTL_Basic_Nutrition_Guidelines.pdf',
  },
  {
    title: 'Healthy for the Holidays',
    url: 'https://triacentral.blob.core.windows.net/dev-storage/resources/weightloss/HealthyfortheHolidays.pdf',
  },
]
export const exerciseLinks = [
  {
    title: 'American Council on Exercise',
    url: 'http://acefitness.org',
  },
]

export const motivationLinks = [
  {
    title: 'Meetup',
    url: 'https://www.meetup.com/topics/weightloss/',
  },
  {
    title: 'American Council on Exercise Instagram',
    url: 'https://www.instagram.com/acefitness/',
  },
]

export const ctlLinks = [
  {
    title: 'Setting up you Withings scale',
    url: 'https://triacentral.blob.core.windows.net/dev-storage/resources/weightloss/Setting%20up%20Your%20Withings%20Scale.pdf',
  },
  {
    title: 'Fit Index Scale Set-up',
    url: 'https://triacentral.blob.core.windows.net/dev-storage/resources/weightloss/FitIndex%20Set-Up%20Guide.pdf',
  },
  {
    title: 'Fit Index Scale Set-up Video | Android',
    url: 'https://youtu.be/pwBOAME9Ink',
  },
  {
    title: 'Fit Index Scale Set-up Video | Apple',
    url: 'https://youtu.be/begGRXzQ6lE',
  },
]

export const otherLinks = [
  {
    title: 'Assessing Your Weight',
    url: 'https://www.cdc.gov/healthyweight/assessing/index.html',
  },
]
