import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import React from 'react'
import styled, { useTheme } from 'styled-components'
import { SectionLink } from 'types/Resource'
import ResourceLinkItem from '../../ResourceDetailPage/ResourceLink'

interface Props {
  title: string
  image: string
  links: SectionLink[]
}
export default function ResourceSection({ image, links, title }: Props) {
  const theme = useTheme()

  return (
    <StyledContainer>
      <Typography font="semi-bold" size={25}>
        {title}
      </Typography>
      <StyledImage src={image} alt="Bowl of vegetables" />
      <div>
        {links.map((link) => {
          return (
            <Row>
              <Icon name={'link'} styles={{ marginRight: 10 }} color={theme.colors.primary1} />
              <ResourceLinkItem title={link.title} url={link.url} />
            </Row>
          )
        })}
      </div>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
  margin-right: 20px;
  @media (max-width: 800px) {
    margin-top: 30px;
    width: 100%;
  }
`

const StyledImage = styled.img`
  width: 100%;
  height: 230px;
  border-radius: 4px;
  margin-bottom: 10px;
  object-fit: cover;
`
